import React from "react";
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { Box } from "@mui/system";
import moment from "moment";
import Lottie from "react-lottie-player";
import { stickerTopicMap } from "../../utils/constants";
import { getAnimationByTopic } from "../../utils/lottie/helper";

interface Props {
  stickers: any[];
  onSelected: (id: string) => void;
}

const StickerListView: React.FC<Props> = ({
  stickers,
  onSelected,
  ...props
}) => {
  return (
    <Grid container>
      {stickers.length > 0 && (
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            position: "relative",
            overflow: "auto",
            maxHeight: 700,
            "& ul": { padding: 0 },
          }}
          subheader={
            <ListSubheader
              sx={{
                backgroundColor: "#f8f8f8",
                fontWeight: "bold",
              }}
            >
              Stickers
            </ListSubheader>
          }
        >
          {stickers
            .sort((a, b) => (+a.timestamp > +b.timestamp ? -1 : 1))
            .map((value, index, array) => (
              <>
                <ListItem
                  onClick={() => {
                    onSelected(`${value.id}`);
                  }}
                >
                  <ListItemIcon>
                    <Lottie
                      loop={false}
                      animationData={getAnimationByTopic(value.topic)}
                      play
                      style={{
                        width: 50,
                        height: 50,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id="list-label-message"
                    primary={
                      <Typography>
                        {stickerTopicMap[value.topic].defaultText}
                      </Typography>
                    }
                    secondary={moment(+value.timestamp).format("Do MMMM 'YY")}
                  />
                </ListItem>
                <Divider variant="fullWidth" />
              </>
            ))}
        </List>
      )}
      {stickers.length === 0 && <EmptyStickers />}
    </Grid>
  );
};

const EmptyStickers = () => {
  return (
    <>
      <List
        sx={{
          width: "100%",
          position: "relative",
          overflow: "auto",
          maxHeight: 500,
          "& ul": { padding: 0 },
        }}
        subheader={
          <ListSubheader
            sx={{
              backgroundColor: "#f8f8f8",
              fontWeight: "bold",
            }}
          >
            Stickers
          </ListSubheader>
        }
      ></List>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={"column"}
        flexGrow={1}
        sx={{
          width: "80%",
        }}
      >
        <Grid container item direction="column" xs={8}>
          <Typography color="textSecondary" align="center">
            <EmailOutlinedIcon />
          </Typography>
          <Typography color="textSecondary" align="center">
            No stickers received yet!
          </Typography>
          <Typography color="textSecondary" align="center">
            Copy and share the link above on WhatsApp, Insta, FB so your friends
            can send you stickers. Keep Hushing!
          </Typography>
        </Grid>
      </Box>
    </>
  );
};

export default StickerListView;
