import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { ExpandMoreOutlined, Edit } from "@mui/icons-material";
import { FACEBOOK_PAGE, INSTA_PAGE } from "../codelibrary/utility/constants";
import { makeStyles } from "@mui/styles";
import Footer from "../footer/Footer";

interface Props {}

const useStyles = makeStyles((theme: any) => ({
  root: {
    minWidth: 180,
    minHeight: 300,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  full_vh: {
    display: "flex",
    alignItems: "center",
    padding: "8px",
    flexGrow: "inherit",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  infoHeader: {
    paddingLeft: "0",
    paddingRight: "0",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    backgroundColor: theme.palette.secondary.main,
    zIndex: -1,
    "& h3": {
      color: theme.palette.primary.contrastText,
      fontWeight: "bold",
    },
  },
  infoContent: {
    marginTop: "1em",
    marginBottom: "1em",
  },
  footer: {
    color: theme.palette.text.primary,
    "& a": {
      color: "#606060",
    },
  },
  copyRight: {
    "& a": {
      color: theme.palette.primary.main,
    },
  },
  bodyMargin: {
    marginTop: theme.mixins.toolbar.minHeight,
  },
}));

const FAQ: React.FC<Props> = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction={"column"}
      alignItems={"center"}
      className={classes.bodyMargin}
    >
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.infoHeader}
      >
        <Typography variant="h3" align="center" className={classes.infoHeader}>
          Frequently Asked Questions
        </Typography>
      </Grid>
      <Grid container justifyContent="center" alignItems="center">
        <Grid
          item
          lg={6}
          md={6}
          xs={10}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ marginTop: "4em" }}
        >
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>
                I forgot my password, what should I do now?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                On the login page, tap on forgot password and follow the
                process.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>
                How can I change my username?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Log into your account &gt; Settings &gt; Edit Profile &gt; Tap
                on the <Edit /> icon
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>
                How can I delete my HushUP account?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Account deletion will be supported soon.</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>
                How to block someone on HushUP?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Turn on the "App Only" feature in the Privacy Settings. This may
                also result in receiving less number of messages.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>
                How to avoid spam messages on HushUP?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Turn on the "App Only" feature in the Privacy Settings. This may
                also result in receiving less number of messages.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Can I use HushUP only for getting anonymous messages?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                HushUP supports anonymous messaging. However, new features are
                being added constantly.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>
                Where can I contact support?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                You can drop a message at HushUP's{" "}
                <Link href={FACEBOOK_PAGE} target="_blank">
                  Facebook
                </Link>{" "}
                or{" "}
                <Link href={INSTA_PAGE} target="_blank">
                  Instagram
                </Link>{" "}
                page.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <Footer quickLinks={true} />
    </Grid>
  );
};

export default FAQ;
